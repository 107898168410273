import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Icon from "../../../../../components/icon"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Text from "../../../../../components/text"
import TextLink from "../../../../../components/textlink"
import PoemHaelfteDesLebens from "../../../../../content/shared/poem-haelfte-des-lebens"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/manuskript-erforschen" />
      }
    >
      <Seo title="Versmaß bestimmen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Versmaß bestimmen
            </Heading>
          </Stack>
          <PoemHaelfteDesLebens />
          <Paragraph>
            An prominentester Stelle, nämlich im Titel und im letzten Vers des
            Gedichts, steht ein Adonius – laut Winfried Menninghaus eine der
            eingängigsten metrischen Figuren überhaupt. Er besteht aus aus einem
            Daktylus und einem Trochäus.
          </Paragraph>
          <Columns space={1} collapse={[true, false]}>
            <Column>
              <Box sx={{ bg: "muted", p: 4, height: "100%" }}>
                <Stack space={1}>
                  <Text bold sans size={4}>
                    Daktylus
                  </Text>
                  <Text color="whisper" sans>
                    betont, unbetont, unbetont
                  </Text>
                  <Text sans size={6} color="primary" letterSpacing="4px">
                    —◡◡
                  </Text>
                </Stack>
              </Box>
            </Column>
            <Column width="content" sx={{ alignSelf: "center" }}>
              <Icon icon="plus"></Icon>
            </Column>
            <Column>
              <Box sx={{ bg: "muted", p: 4, height: "100%" }}>
                <Stack space={1}>
                  <Text bold sans size={4}>
                    Trochäus
                  </Text>
                  <Text color="whisper" sans>
                    betont, unbetont
                  </Text>
                  <Text sans size={6} color="primary" letterSpacing="4px">
                    —◡
                  </Text>
                </Stack>
              </Box>
            </Column>
            <Column width="content" sx={{ alignSelf: "center" }}>
              <Icon icon="equals"></Icon>
            </Column>
            <Column>
              <Box sx={{ bg: "muted", p: 4, height: "100%" }}>
                <Stack space={1}>
                  <Text bold sans size={4}>
                    Adonius
                  </Text>
                  <Text color="whisper" sans>
                    betont, unbetont, unbetont, betont, unbetont
                  </Text>
                  <Text sans size={6} color="primary" letterSpacing="4px">
                    —◡◡ —◡
                  </Text>
                </Stack>
              </Box>
            </Column>
          </Columns>
          <Paragraph>
            Manchmal steht anstelle des Trochäus auch ein Spondeus, ein Versmaß,
            in dem zwei betonte Silben aufeinanderprallen.
          </Paragraph>

          <Columns space={1} collapse={[true, false]}>
            <Column>
              <Box sx={{ bg: "muted", p: 4, height: "100%" }}>
                <Stack space={1}>
                  <Text bold sans size={4}>
                    Daktylus
                  </Text>
                  <Text color="whisper" sans>
                    betont, unbetont, unbetont
                  </Text>
                  <Text sans size={6} color="primary" letterSpacing="4px">
                    —◡◡
                  </Text>
                </Stack>
              </Box>
            </Column>
            <Column width="content" sx={{ alignSelf: "center" }}>
              <Icon icon="plus"></Icon>
            </Column>
            <Column>
              <Box sx={{ bg: "muted", p: 4, height: "100%" }}>
                <Stack space={1}>
                  <Text bold sans size={4}>
                    Spondeus
                  </Text>
                  <Text color="whisper" sans>
                    betont, betont
                  </Text>
                  <Text sans size={6} color="primary" letterSpacing="4px">
                    ——
                  </Text>
                </Stack>
              </Box>
            </Column>
            <Column width="content" sx={{ alignSelf: "center" }}>
              <Icon icon="equals"></Icon>
            </Column>
            <Column>
              <Box sx={{ bg: "muted", p: 4, height: "100%" }}>
                <Stack space={1}>
                  <Text bold sans size={4}>
                    Adonius
                  </Text>
                  <Text color="whisper" sans>
                    betont, unbetont, unbetont, betont, betont
                  </Text>
                  <Text sans size={6} color="primary" letterSpacing="4px">
                    —◡◡ ——
                  </Text>
                </Stack>
              </Box>
            </Column>
          </Columns>
          <Paragraph>
            Der Adonius hat seinen Namen von den Klagen um den Tod des schönen
            griechischen Jünglings Adonis, der sowohl Frauen als auch Männer
            betört haben soll: »ô ton Adônin« (—◡◡ | —◡).
          </Paragraph>
          <Paragraph>
            Die griechische Dichterin Sappho hat dieses metrische Schema zu
            ihrem Markenzeichen gemacht: Ihre lediglich fragmentarisch
            überlieferten Gedichte, für die sie eine ganz eigene Form der Ode
            kreierte, schließen stets mit dieser Betonungsfolge ab.
          </Paragraph>
          <Paragraph>
            Hölderlin hat die Form der Ode in der Tradition des griechischen
            Dichters Pindar und des deutschen Dichters Friedrich Gottlieb
            Klopstock weitergeführt und auf die deutsche Sprache übertragen.
            Unter seinen Gedichten finden sich viele asklepiadeische und
            alkäische Oden. Allerdings hat er nur eine einzige sapphische Ode
            angefertigt: die Ode ›Unter den Alpen gesungen‹. Eine zweite, die
            den Titel ›Sapphos Schwanengesang‹ tragen sollte, hat er nicht
            weiter ausgeführt. Die Schwäne allerdings tauchten wenig später im
            Entwurf zu ›Hälfte des Lebens‹ wieder auf.
          </Paragraph>
          <Heading level={5}>Mehr zur Metrik von ›Hälfte des Lebens‹</Heading>
          <Stack space={3}>
            <Text size={3}>
              <Heading as="span">Wolfram Groddeck</Heading>:{" "}
              <TextLink href="https://www.transcript-verlag.de/978-3-89942-606-9/weiterlesen/">
                Zahl, Maß und Metrik in Hölderlins Gedicht ›Hälfte des Lebens‹
              </TextLink>
            </Text>
            <Text color="whisper">
              In: Weiterlesen. Literatur und Wissen, hrsg. von Ulrike Bergermann
              und Elisabeth Strowick, Bielefeld 2007
            </Text>
          </Stack>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
